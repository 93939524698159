<template>
  <div>
    
    <article class="news-item" v-for="item in reglement">
      <a :href="item.url" :title="getLinkTitle(item.title)" target="_blank">
        <img :src="item.image" alt="" loading="lazy" />
        <h3><span>{{item.title}}</span></h3>
      </a>
    </article>
    
  </div>
</template>

<script>
export default {
  name: 'reglementation',
  data() {
    return {
      reglement: null
    }
  },
  mounted() {
    this.getReglement()
  },
  methods: {
    getReglement: async function() {
      await this.$http.get('rss/reglementationAssmat')
      .then(resp => {
        this.reglement = resp.data.listeActusRss
      })
    },
    getLinkTitle: function(title) {
      return title+' — Nouvelle fenêtre'
    }
  }
}
</script>