<template>
	<main>
		<header class="layer-primary">
			<div class="main-img">
				<img
				loading="lazy"
				src="https://www.maine-et-loire.fr/fileadmin/Departement/Services-informations/Enfance/DEP49-AssMat-BertrandBechard-31.jpg"
				alt=""
				width="1800"
				height="650"
				/>
			</div>
		</header>
		
		<section class="section-content" id="section-content">
			<header>
				<div class="sidebar-primary txtcenter" style="background-image: url(https://www.maine-et-loire.fr/typo3conf/ext/ressource_maine-et-loire/img/sidebar-primary-bg-icon.svg); background-position: bottom right; background-repeat: no-repeat; background-size: contain">
					<span class="h2-like">Accès directs</span>
					<div class="ce-textpic ce-center ce-above">
						<div class="ce-bodytext">
							<ul>
								<li>
									<a href="https://moncompte-assmat.maine-et-loire.fr" target="_blank" title="Se connecter à mon compte - Nouvelle fenêtre">Se connecter à mon compte</a>
								</li>
								<li>
									<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel" target="_blank" title="Comment devenir assistant maternel - Nouvelle fenêtre">Comment devenir assistant maternel</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				
				<ul id="site-nav-breadcrumbs" class="site-nav-breadcrumbs">
					<li>
						<router-link :to="{name: 'accueil'}">Accueil</router-link>
					</li>
					<li>Espace assistants maternels</li>
				</ul>
				<h1>Assistant maternel : bienvenue sur votre espace personnel</h1>
				
				<p class="intro" id="introPage">
					Vous êtes assistante ou assistant maternel ou souhaitez le devenir ?
					Agrément, droits et obligations, formation, rappel de la
					règlementation... Retrouvez ici toutes les informations utiles pour
					vous permettre d'exercer votre métier en toute quiétude.
					<br />
					Vous accéderez également sur cette page à votre espace personnel qui
					vous permettra d’actualiser en ligne vos disponibilités.
				</p>
				<div class="toolbox"></div>
			</header>
			<div>
				<aside class="sidebar-secondary">
					<nav class="page-nav" id="page-nav" aria-label="Sommaire de la page">
						<span>Sommaire</span>
						<ul>
							<li><router-link to="#effectuer-vos-demarches-en-ligne">Effectuer vos démarches en ligne</router-link></li>
							<li><router-link to="#le-metier-dassistant-maternel">Le métier d'assistant maternel</router-link></li>
							<li><router-link to="#accueil-quotidien">L’accueil au quotidien</router-link></li>
							<li><router-link to="#cadre-legal">Le cadre légal</router-link></li>
							<li><router-link to="#rappel">Rappel</router-link></li>
						</ul>
					</nav>
					<section class="contact">
						<div class="ce-textpic ce-center ce-above">
							<div class="ce-bodytext">
								<p>
									<strong>Département de Maine-et-Loire</strong>
									<br />
									Service de Protection maternelle et infantile
								</p>
								<p>
									<b>Tél :</b>
									<a href="tel:+33241814818" title="Appeler le 0241814107">02 41 81 48 18</a>
								</p>
								<p>
									<b>Envoyer un message :</b>
									<a href="mailto:pmi@maine-et-loire.fr" title="Envoyer un courriel à pmi@maine-et-loire.fr - Fenêtre de messagerie">pmi@maine-et-loire.fr</a>
								</p>
							</div>
						</div>
					</section>
				</aside>
				<article id="main-content">
					<h2 id="effectuer-vos-demarches-en-ligne">Effectuer vos démarches en ligne</h2>
					<p>
						Sur le site assmat.maine-et-loire.fr, chaque assistante maternelle
						dispose d’un espace personnel depuis lequel effectuer les démarches
						suivantes :
					</p>
					<ul>
						<li>informer la PMI des départs/arrivées d’un enfant;</li>
						<li>
							signaler ses places disponibles pour être contacté par des parents
							en recherche d’un ou d’une assistante maternelle ;
						</li>
						<li>informer la PMI de changements de coordonnées ;</li>
						<li>
							masquer vos données, si vous ne souhaitez pas être visible sur ce
							site.
						</li>
					</ul>
					
					<p>
						<strong
						>Rappel : La saisie et l’actualisation de vos disponibilités sont
						à effectuer par vos soins, et ce de manière régulière. Le
						Département n’intervient pas sur ce niveau d’information.</strong
						>
					</p>
					
					<div class="essential">
						<h2>Déclaration : modification et départ</h2>
						<p>
							Pour déclarer les modifications et les fins d'accueil d'une
							déclaration d'arrivée qui n'a pas été transmise depuis votre
							espace personnel, utilisez l'ancien formulaire de déclaration :
							<a href="https://declaration-accueil.maine-et-loire.fr/" title="Déclarer une modification/départ d'un enfant avec l'ancien formulaire - Nouvelle fenêtre" target="_blank">déclarer une modification/départ d'un enfant</a>
						</p>
					</div>
					
					<a href="https://moncompte-assmat.maine-et-loire.fr" class="btn-cta-standard-large" target="_blank" title="Se connecter à mon compte - Nouvelle fenêtre">Se connecter à mon compte</a>
					
					<h2 id="le-metier-dassistant-maternel">Le métier d'assistant maternel</h2>
					<section class="layer-si layer-without-ending-link">
						<div class="layer-summary-filters">
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel" target="_blank" title="Consulter Devenir assistant maternel - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Devenir assistant maternel
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/droits-et-obligations#c9481" target="_blank" title="La formation - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">La formation</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/creer-une-mam" target="_blank" title="Les Maisons d’assistants maternels - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Les Maisons d’assistants maternels 
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/la-creche-familiale" target="_blank" title="La crèche familiale - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											La crèche familiale
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/agrement#c842" target="_blank" title="Les réunions d’informations - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">Les réunions d’informations</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/modes-de-garde/relais-assistants-maternels" target="_blank" title="Les relais petite enfance - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">Les relais petite enfance</p>
									</div>
								</a>
							</div>
						</div>
					</section>
					
					<h2 id="accueil-quotidien">L’accueil au quotidien</h2>
					
					<section class="layer-without-ending-link">
						<div class="layer-summary-col3">
							<div class="layer-summary-item no-flip">
								<img loading="lazy" aria-hidden="true" src="https://www.maine-et-loire.fr/fileadmin/Departement/Actus/Actualites/2021/2021-mars/DEP49-administrer-medicaments-assmat-RS.jpg" alt="" width="1200" height="628"/>
								<p class="layer-summary-item-title">
									<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/medicaments" target="_blank" title="La santé de l’enfant - Nouvelle fenêtre">La santé de l’enfant</a>
								</p>
							</div>
							<div class="layer-summary-item no-flip">
								<img loading="lazy" aria-hidden="true" src="https://www.maine-et-loire.fr/fileadmin/Departement/Actus/Actualites/2021/2021-mars/DEP49-securite-domicile-assmat-RS.jpg" alt="" width="1200" height="628"/>
								<p class="layer-summary-item-title">
									<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/securite-du-domicile" target="_blank" title="Sécurité du domicile - Nouvelle fenêtre">Sécurité du domicile</a>
								</p>
							</div>
							<div class="layer-summary-item no-flip">
								<img loading="lazy" aria-hidden="true" src="https://www.maine-et-loire.fr/fileadmin/Departement/Actus/Actualites/2021/2021-mars/dep49-siege-auto-shutterstock-RS.jpg" alt="" width="1200" height="628" />
								<p class="layer-summary-item-title">
									<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/organiser-une-sortie" target="_blank" title="Organiser une sortie - Nouvelle fenêtre">Organiser une sortie</a>
								</p>
							</div>
							<div class="layer-summary-item no-flip">
								<img loading="lazy" aria-hidden="true" src="https://www.maine-et-loire.fr/fileadmin/Departement/Actus/Actualites/2021/2021-mars/DEP49-assmat-accueil-stagiaire.jpg" alt="" width="1200" height="628"/>
								<p class="layer-summary-item-title">
									<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/accueillir-un-stagiaire" target="_blank" title="Accueillir un stagiaire - Nouvelle fenêtre">Accueillir un stagiaire</a>
								</p>
							</div>
						</div>
					</section>
					
					<h2 id="cadre-legal">Le cadre légal</h2>
					<section class="layer-si layer-without-ending-link">
						<div class="layer-summary-filters">
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/agrement" target="_blank" title="L’agrément - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											L’agrément
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/enfance-et-famille/metiers-de-la-petite-enfance/devenir-assistant-maternel/droits-et-obligations" target="_blank" title="Droits et obligations - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Droits et obligations
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/contractualisation" target="_blank" title="Contrat, rémunération - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Contrat, rémunération
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/documents-a-demander" target="_blank" title="Les documents à demander - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Les documents à demander
										</p>
									</div>
								</a>
							</div>
							<div class="layer-summary-item no-flip">
								<a href="https://www.maine-et-loire.fr/aides-et-services/professionnels/enfance/reglementation-assistants-maternels/renouveller-ou-faire-modifier-son-agrement" target="_blank" title="Renouveler son agrément - Nouvelle fenêtre">
									<div class="layer-summary-item-front">
										<p class="layer-summary-item-title">
											Renouveler son agrément
										</p>
									</div>
								</a>
							</div>
						</div>
					</section>
					
					<div class="layer-news-list" id="news-container-401">
						<h2 id="rappel">Rappel</h2>
						<reglementation></reglementation>
					</div>
					
					<!--Fin site public-->
					
					<!--Suite ecosysteme-->
				</article>
			</div>
			
			<footer>
				<div class="layer-news-list">
					<h2>Actualité de l'enfance en Maine-et-Loire</h2>
					<actualities></actualities>
				</div>
			</footer>
		</section>
	</main>
</template>

<script>
import actualities from "@/components/actualities.vue";
import reglementation from "@/components/reglementation.vue";

export default {
	name: "assistant-maternel",
	components: {
		actualities,
		reglementation,
	},
};
</script>
